import { render, staticRenderFns } from "./textManagement.vue?vue&type=template&id=3b2db398&scoped=true"
import script from "./textManagement.vue?vue&type=script&lang=js"
export * from "./textManagement.vue?vue&type=script&lang=js"
import style0 from "./textManagement.vue?vue&type=style&index=0&id=3b2db398&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b2db398",
  null
  
)

export default component.exports